import type { Timezone } from '../../components/Beertime'

export interface ITimeResponse {
  abbreviation: string
  countryCode: string
  countryName: string
  dst: string
  dstEnd: number
  dstStart: number
  formatted: string
  gmtOffset: number
  message: string
  nextAbbreviation: string
  status: string
  timestamp: number
  zoneName: string
}

const ZONE_TIME_API = 'https://api.timezonedb.com/v2.1/get-time-zone?key=ONEUO50OR6VC'

const latLngForTimezones = {
  amsterdam: {
    latitude: 52.370_216,
    longitude: 4.895_168
  },
  asia: {
    latitude: 35.689_487,
    longitude: 139.691_706
  },
  'south-america': {
    latitude: -12.046_374,
    longitude: -77.042_793
  },
  russia: {
    latitude: 55.755_826,
    longitude: 37.6173
  }
}

export const fetchTimeForZone = async(timezone: Timezone): Promise<number> => {
  const { latitude, longitude } = latLngForTimezones[timezone]
  const timeForZone: ITimeResponse = await fetch(`${ZONE_TIME_API}&format=json&by=position&lat=${latitude}&lng=${longitude}`).then(res => res.json())

  if (timeForZone.status === 'OK') {
    return timeForZone.timestamp
  } return 0
}
