/* eslint-disable max-statements */
/* eslint-disable react/no-unknown-property */
/*
 *Auto-generated by: https://github.com/pmndrs/gltfjsx
 */
import React, { useState } from 'react'
import { useGLTF } from '@react-three/drei'
import type { GLTF } from 'three-stdlib'
import type {
  Mesh,
  MeshPhysicalMaterial,
  MeshStandardMaterial
} from 'three'
import { Color } from 'three'

import {
  useSpring,
  animated,
  config
} from '@react-spring/three'

// Types
import type { ThreeEvent } from '@react-three/fiber'
import type { Timezone } from './Beertime'

type GLTFResult = GLTF & {
  nodes: {
    rock: Mesh
    pagoda: Mesh
    ['moscow-tower']: Mesh
    Icosphere004: Mesh
    Icosphere004_1: Mesh
    Water: Mesh
    NorthPole: Mesh
    Southpole: Mesh
    Stairs: Mesh
    Plane: Mesh
    Plane_1: Mesh
    ['ams-base']: Mesh
    ['ams-house']: Mesh
    ['ams-hook']: Mesh
  }
  materials: {
    ['Material.003']: MeshStandardMaterial
    ['Material.001']: MeshStandardMaterial
    ['Material.002']: MeshStandardMaterial
    Material: MeshStandardMaterial
    water: MeshPhysicalMaterial
    Snow: MeshStandardMaterial
    Temple: MeshStandardMaterial
    TempleShadow: MeshStandardMaterial
    ['Material.006']: MeshStandardMaterial
  }
}

interface Props {
  setIsOpen: (value: React.SetStateAction<boolean>) => void
  setTimezone: (value: React.SetStateAction<Timezone>) => void
}

export const World: React.FC<Props> = ({ setIsOpen, setTimezone }) => {
  const { nodes, materials } = useGLTF('/globe-v12.glb') as unknown as GLTFResult

  const handlePOIClick = (name: Timezone, evt: ThreeEvent<PointerEvent>) => {
    // eslint-disable-next-line no-console
    console.log(name, evt)
    setTimezone(name)
    setIsOpen(true)
  }

  const [isHoveringTemple, setIsHoveringTemple] = useState(false)
  const { scale: templeScale } = useSpring({
    scale: isHoveringTemple
      ? 1.05
      : 1,
    config: config.gentle
  })

  const [isHoveringPagoda, setIsHoveringPagoda] = useState(false)
  const { scale: pagodaScale } = useSpring({
    scale: isHoveringPagoda ? 1.05 : 1,
    config: config.gentle
  })

  const [isHoveringAmsterdam, setIsHoveringAmsterdam] = useState(false)
  const { scale: amsterdamScale } = useSpring({
    scale: isHoveringAmsterdam ? 1.05 : 1,
    config: config.gentle
  })

  const [isHoveringMoscow, setIsHoveringMoscow] = useState(false)
  const { scale: moscowScale } = useSpring({
    scale: isHoveringMoscow ? 1.05 : 1,
    config: config.gentle
  })

  const waterMaterial = materials.water

  waterMaterial.color = new Color('#1bfff4')

  return (
    <group dispose={null} scale={2.5}>
      <animated.group
        onPointerDown={(evt) => handlePOIClick('asia', evt)}
        onPointerEnter={() => setIsHoveringPagoda(true)}
        onPointerLeave={() => setIsHoveringPagoda(false)}
        scale={pagodaScale}
      >
        <mesh geometry={nodes.rock.geometry}
          material={materials['Material.003']}
          position={[
            0.28,
            0.43,
            -0.85
          ]}
          rotation={[
            -0.94,
            0.4,
            -0.28
          ]}
          scale={[
            0.05,
            0.04,
            0.05
          ]}
        />
        <mesh geometry={nodes.pagoda.geometry}
          material={materials['Material.001']}
          position={[
            0.32,
            0.49,
            -0.91
          ]}
          rotation={[
            -0.4,
            1.16,
            -0.69
          ]}
          scale={0.05}
        />
      </animated.group>
      <mesh geometry={nodes.Icosphere004.geometry} material={materials['Material.002']} />
      <mesh geometry={nodes.Icosphere004_1.geometry} material={materials.Material} />
      <mesh geometry={nodes.Water.geometry} material={waterMaterial} scale={1.02} />
      <mesh geometry={nodes.NorthPole.geometry} material={materials.Snow} />
      <mesh geometry={nodes.Southpole.geometry} material={materials.Snow} />
      <animated.group
        onPointerDown={(evt) => handlePOIClick('russia', evt)}
        onPointerEnter={() => setIsHoveringMoscow(true)}
        onPointerLeave={() => setIsHoveringMoscow(false)}
        scale={moscowScale}
      >
        <mesh geometry={nodes['moscow-tower'].geometry}
          material={materials['Material.001']}
          position={[
            0.68,
            0.81,
            -0.18
          ]}
          rotation={[
            1.7,
            0.92,
            -1.85
          ]}
          scale={0.06}
        />
      </animated.group>

      <animated.group
        onPointerDown={(evt) => handlePOIClick('amsterdam', evt)}
        onPointerEnter={() => setIsHoveringAmsterdam(true)}
        onPointerLeave={() => setIsHoveringAmsterdam(false)}
        scale={amsterdamScale}
      >
        <mesh geometry={nodes['ams-base'].geometry}
          material={materials['Material.006']}
          position={[
            0.61,
            0.43,
            0.66
          ]}
        />
        <mesh geometry={nodes['ams-house'].geometry}
          material={materials['Material.006']}
          position={[
            0.71,
            0.46,
            0.75
          ]}
        />
        <mesh geometry={nodes['ams-hook'].geometry}
          material={materials['Material.006']}
          position={[
            0.77,
            0.47,
            0.79
          ]}
        />
      </animated.group>

      <animated.group scale={templeScale}>
        <mesh geometry={nodes.Stairs.geometry}
          material={materials.Temple}
          onPointerDown={(evt) => handlePOIClick('south-america', evt)}
          onPointerEnter={() => setIsHoveringTemple(true)}
          onPointerLeave={() => setIsHoveringTemple(false)}
          position={[
            -0.81,
            -0.53,
            0.43
          ]}
          rotation={[
            0.27,
            0.61,
            1.86
          ]}
          scale={0.07}
        />
        <group position={[
          -0.81,
          -0.53,
          0.43
        ]}
        rotation={[
          0.27,
          0.61,
          1.86
        ]}
        scale={0.07}
        >
          <mesh geometry={nodes.Plane.geometry} material={materials.Temple} />
          <mesh geometry={nodes.Plane_1.geometry} material={materials.TempleShadow} />
        </group>
      </animated.group>
    </group>
  )
}

useGLTF.preload('/globe-v12.glb')
