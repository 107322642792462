import React, {
  useCallback, useEffect, useState
} from 'react'
import type { ReactNode } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

interface IModalProps {
  children?: ReactNode
  isOpen: boolean
  setIsOpen: (value: React.SetStateAction<boolean>) => void
}

export default function useModal() {
  const [isOpen, setIsOpen] = useState(false)

  return {
    isOpen,
    setIsOpen
  }
}

export const Modal: React.FC<IModalProps> = ({
  setIsOpen,
  isOpen,
  children
}: IModalProps) => {
  const escFunction = useCallback((event: { key: string }) => {
    if (event.key === 'Escape') {
      setIsOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [])

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          animate={{ opacity: 1 }}
          className="modal-overlay flex absolute items-center justify-center w-screen h-screen z-30"
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          transition={{
            ease: 'circOut',
            duration: .2
          }}
        >
          <button
            className="absolute top-6 right-6 p-4 z-10 text-white text-5xl"
            onClick={() => setIsOpen(false)}
          >
            X
          </button>

          <motion.div
            animate={{
              opacity: 1,
              scale: 1
            }}
            className="modal-box flex items-center w-full h-full overflow-hidden text-white relative"
            exit={{
              opacity: 0,
              scale: 0.94
            }}
            initial={{
              opacity: 0,
              scale: 0.94
            }}
            transition={{
              ease: 'circOut',
              duration: .2,
              delay: .1
            }}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
