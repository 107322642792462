import React, { useState } from 'react'
import { motion } from 'framer-motion'
import './App.css'

// Components
import { Container } from './components/Container'
import useModal, { Modal } from './components/Modal'
import { Beertime } from './components/Beertime'

// Types
import type { Timezone } from './components/Beertime'

function App() {
  const {
    isOpen,
    setIsOpen
  } = useModal()

  const [timezone, setTimezone] = useState('amsterdam' as Timezone)

  return (
    <div className="App">
      <div className="w-full h-full flex items-center justify-center">
        <motion.img
          alt="Is het biertijd logo"
          animate={{
            opacity: 1,
            transform: 'scale(1)'
          }}
          className="max-w-[800px] absolute z-10 pointer-events-none select-none"
          initial={{
            opacity: 0,
            transform: 'scale(0.88)'
          }}
          src="/logo.webp"
          transition={{
            delay: 1,
            repeat: 1,
            repeatType: 'reverse',
            repeatDelay: 1.5,
            duration: 0.5,
            type: 'spring'
          }}
        />
        <Container
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setTimezone={setTimezone}
        />
      </div>
      <motion.a
        animate={{
          opacity: 1,
          transform: 'scale(1)'
        }}
        className="absolute bottom-5 right-5 z-10 w-[112px]"
        href="https://yummygum.com"
        initial={{
          opacity: 0,
          transform: 'scale(0.88)'
        }}
        rel="noreferrer"
        target="_blank"
        transition={{
          delay: 5,
          duration: 0.5,
          type: 'spring'
        }}
      >
        <img
          alt="Is het biertijd? logo"
          src="/logo.webp"
        />
      </motion.a>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <Beertime timezone={timezone} />
      </Modal>
    </div>
  )
}

export default App
