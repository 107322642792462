import React, { useMemo } from 'react'
import type { ReactNode } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

// Components
import { Button } from './Button'

// Helpers
import isBeerTime from '../helpers/utils/isBeerTime'

export type Timezone = 'amsterdam' | 'south-america' | 'asia' | 'russia'

interface IBeertimeProps {
  children?: ReactNode
  element?: HTMLElement
  timezone: Timezone
}

const sentence = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.2,
      staggerChildren: 0.05,
      type: 'spring'
    }
  }
}

const letter = {
  hidden: {
    opacity: 0,
    y: -50
  },
  visible: {
    opacity: 1,
    y: 0
  }
}

const isTimeTexts = {
  amsterdam: 'Proost, het is drinkie tijd',
  'south-america': 'Hola, it is time for drinks!',
  asia: '你好, it is time for drinks!',
  russia: 'Привет, it is time for drinks!'
}

const notTimeTexts = {
  amsterdam: 'Nog eventjes wachten...',
  'south-america': 'Nope, still have to wait a bit!',
  asia: '不, still have to wait a bit!',
  russia: 'Нет, still have to wait a bit!'
}

/*
 * const downloadScreenshot = async(displayMediaOptions: DisplayMediaStreamConstraints | undefined) => {
 *   try {
 *     const displayMedia = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
 */

/*
 *     displayMedia.getVideoTracks()[0].addEventListener('ended', () => {
 *       displayMedia.getVideoTracks()[0].stop()
 *     })
 *   } catch (error) {
 *     console.error(`Error:${error}`)
 *     return null
 *   }
 * }
 */

export const Beertime: React.FC<IBeertimeProps> = ({
  timezone = 'amsterdam'
}) => {
  const [isTime, setIsTime] = React.useState(false)
  const [showCopiedToClipboardMessage, setShowCopiedToClipboardMessage] = React.useState(false)

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(window.location.href)
    setShowCopiedToClipboardMessage(true)

    setTimeout(() => {
      setShowCopiedToClipboardMessage(false)
    }, 2000)
  }

  useMemo(async() => {
    setIsTime(await isBeerTime(timezone))
  }, [timezone])

  return (
    <React.Fragment>
      <div>
        <motion.h1
          animate="visible"
          className="mb-8 lg:text-8xl md:text-6xl text-5xl max-w-[852px] leading-snug flex flex-wrap"
          initial="hidden"
          variants={sentence}
        >
          { isTime
            ? [...isTimeTexts[timezone]]
            : [...notTimeTexts[timezone]].map((char, index) => {
              return (
                <motion.span
                  className="md:min-w-[28px] sm:min-w-[10px] min-w-[8px]"
                  key={`${char}-${index}`}
                  variants={letter}
                >
                  {char === '' ? ' ' : char}
                </motion.span>
              )
            })}

        </motion.h1>
        <p className="sub max-w-[400px] text-2xl mb-20">
          Drinks are always better with friends, share the love!
        </p>

        <div className="inline-grid grid-flow-col gap-6">
          {/* <Button onClick={() => downloadScreenshot({
            video: false
          })}
          >
            Download
          </Button> */}

          <Button onClick={() => copyLinkToClipboard()}>
            Share
          </Button>

          <AnimatePresence>
            {showCopiedToClipboardMessage && (
              <motion.p
                animate={{ opacity: 1 }}
                className="text-2xl text-[#46DDCB] place-self-center"
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
              >
                <span aria-label="Checkmark" role="img">✅</span> Link copied to clipboard!
              </motion.p>
            )}
          </AnimatePresence>
        </div>
      </div>

      <div className="absolute bottom-0 right-[-80px] overflow-hidden pointer-events-none">
        <motion.img
          alt="Visual of the moon"
          animate={{
            opacity: 1,
            transform: 'scale(1)'
          }}
          className="max-w-[90vw] max-h-[1080px]"
          initial={{
            opacity: 0,
            transform: 'scale(.92)'
          }}
          src="/underlay.png"
          transition={{
            delay: 0.2,
            duration: 0.5,
            type: 'spring'
          }}
        />
      </div>
    </React.Fragment>
  )
}
