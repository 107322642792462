/* eslint-disable max-statements */
import type { Timezone } from '../../components/Beertime'
import { fetchTimeForZone } from './getTimeForZone'

const isBeerTime = async(timezone?: Timezone) => {
  const zoneTimestamp = await fetchTimeForZone(timezone ?? 'amsterdam')
  const date = new Date(zoneTimestamp * 1000)

  const hour = date.getHours()
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]
  const dayName = days[date.getDay()]

  const correctDay = dayName === 'Friday' || dayName === 'Saturday' || dayName === 'Sunday'
  const currentYear = date.getFullYear()
  const kingsDay = new Date(`${currentYear}-04-27`)

  function sameDay(d1: Date, d2: Date) {
    return d1.getFullYear() === d2.getFullYear()
      && d1.getMonth() === d2.getMonth()
      && d1.getDate() === d2.getDate()
  }

  const isTime = (hour >= 16 && hour >= 0 && correctDay) || (sameDay(date, kingsDay))

  return isTime
}

export default isBeerTime
