import React from 'react'
import type { ReactNode } from 'react'

interface IButtonProps {
  children?: ReactNode
  element?: HTMLElement
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
}

export const Button: React.FC<IButtonProps> = ({
  children,
  onClick
}) => {
  return (
    <button
      className="md:text-[21px] text-[16px] h-12 px-6 leading-8 md:px-6 md:h-12 flex items-center justify-center bg-[#46DDCB] text-[#222828E5] button"
      onClick={onClick}
    >
      {children}
    </button>
  )
}
