/* eslint-disable react/no-unknown-property */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import { motion } from 'framer-motion'
import React, {
  Suspense,
  useRef,
  useState
} from 'react'
import {
  Canvas,
  useFrame
} from '@react-three/fiber'
import {
  PresentationControls,
  Html,
  Stars
} from '@react-three/drei'
import {
  EffectComposer,
  Noise,
  DepthOfField,
  Bloom
} from '@react-three/postprocessing'
import {
  useSpring,
  animated,
  config
} from '@react-spring/three'
import { World } from './World'
import { Timezone } from './Beertime'

interface IContainerProps {
  setIsOpen: (value: React.SetStateAction<boolean>) => void
  setTimezone: (value: React.SetStateAction<Timezone>) => void
  isOpen: boolean
}

const blur = {
  hidden: {
    opacity: 1,
    filter: 'blur(10px)'
  },
  visible: {
    opacity: 1,
    filter: 'blur(0px)',
    transition: {
      delay: 4.8,
      duration: .3,
      ease: 'linear'
    }
  }
}

export const Model: React.FC<IContainerProps> = ({
  setTimezone,
  isOpen,
  setIsOpen
}) => {
  const [hidden, setVisible] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null)

  useFrame(() => {
    if (ref.current?.rotation) {
      ref.current.rotation.y += 0.0015
    }
  })

  const htmlStyle = {
    transition: 'all 0.2s',
    opacity: hidden ? 0 : 1,
    transform: `scale(${hidden ? 0.5 : 1})`,
    color: 'white',
    fontSize: '24px',
    zIndex: 1
  }

  const { scale: worldScale } = useSpring({
    scale: isOpen ? 0.6 : 1,
    config: config.gentle
  })

  return (
    <Suspense fallback={<mesh>Loading...</mesh>}>
      <PresentationControls
        azimuth={[Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY]}
        config={config.molasses}
        cursor
        enabled
        global={false}
        polar={[0.23, 0]}
        snap={false}
        speed={0.9}
        zoom={1.1}
      >
        <group ref={ref}>
          <animated.group scale={worldScale}>
            <World setIsOpen={setIsOpen} setTimezone={setTimezone} />
          </animated.group>
          <Html
            className="content"
            occlude
            onOcclude={setVisible as (visible: boolean) => null}
            position={[
              0,
              0,
              -3
            ]}
            style={htmlStyle}
            transform
          >
            {/* Rocket here */}
          </Html>

          <Stars count={300} depth={50} factor={10} fade radius={50} saturation={1} speed={1} />
        </group>
      </PresentationControls>
    </Suspense>
  )
}

export const Container: React.FC<IContainerProps> = ({
  isOpen,
  setIsOpen,
  setTimezone
}) => {
  return (
    <motion.div
      animate="visible"
      className="w-full h-full flex items-center justify-center will-change-auto"
      initial="hidden"
      variants={blur}
    >
      <Canvas
        camera={{
          fov: 100,
          position: [
            5,
            0,
            0
          ]
        }}
        className="w-full h-full z-0"
        dpr={[1, 2]}
        flat
      >
        <Model
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setTimezone={setTimezone}
        />

        <EffectComposer>
          <Bloom height={600} luminanceSmoothing={3} luminanceThreshold={3} />
          <Noise opacity={0.3} premultiply />
          <DepthOfField
            blur={20}
            bokehScale={10}
            focalLength={5}
            focusDistance={isOpen ? 1 : 0}
          />
        </EffectComposer>

        <ambientLight intensity={0.4} />
        <pointLight
          intensity={2}
          position={[
            30,
            60,
            10
          ]}
          scale={100}
        />
      </Canvas>
    </motion.div>
  )
}
